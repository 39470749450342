.form-button {
  padding: 7px 19px;
  border: 1px solid #a1713d;
  border-radius: 50px;
  background-color: #f5edd4;
  margin-left: 14px;
}
.form-wrapper {
  display: flex;
  align-items: center;
}
.form-submit-btn {
  padding: 11px 13px;
  border: 1px solid #f86805;
  font-weight: 600;
  color: #ab4600;
  background-color: #f5edd4;
  border-radius: 30px;
  box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.2);
  font-size: 18px;
}
.date-field {
  background-image: url(../../Assets/img/calendar.svg);
  background-repeat: no-repeat;
  background-position: 95% 4px;
}
.shilpImg {
  width: 100%;
  margin: auto;
  max-width: 650px;
}
.shilpContent {
  margin-top: 100px;
  text-align: center;
}
.shilpContent h3 {
  color: #543c00;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
.icon-item {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 1px solid #a1713d;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5edd4;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
  margin: 0px 16px;
}
.icon-list {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding-left: 0px;
}
.download-btn {
  padding: 4px 8px;
  border: 1px solid #f86805;
  font-weight: 400;
  color: #ab4600;
  background-color: #f5edd4;
  border-radius: 13px;
  box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.2);
  font-size: 16px;
}
.icon-block {
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .shilpContent h3 {
    font-size: 22px;
  }
  .icon-item {
    height: 60px;
    width: 60px;
    margin: 0px 10px;
  }
  .icon-block {
    margin-bottom: 30px;
  }
}
