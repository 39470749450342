.main_body {
  background-color: #f5ead6 !important;
  background-image: url(../../../public/asset/images/background/default.jpg);
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2%;
  animation: fadeInAnimation ease-in 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-repeat: no-repeat;
  background-size: cover;
}

.main_body_withoutbg {
  background-color: #f5ead6 !important;
  background-image: url(../../../public/asset/images/background/plain.jpg);
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2%;
  animation: fadeInAnimation ease-in 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.ease-in-anim {
  /* animation: fadeInAnimation ease-in 2s; */
  animation: fadeInAnimation ease-in 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  animation: fadeOutAnimation ease-out 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 6s;
}

.main_head {
  color: rgb(134, 109, 64);
  font-family: "Inter";
  font-weight: 600;
  line-height: 29px;
  font-size: 30px;
  text-shadow: 0px 0px 1px #000000;
}
.m_head {
  font-family: "Inter";
  font-weight: 600;
  line-height: 29px;
  font-size: 35px;
  color: rgb(59, 38, 0) !important;
}
.sub_head {
  color: rgb(134, 109, 64);
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin-top: 2%;
}
.sub_title {
  color: #476807;
  text-align: center;
  font-family: "Inter";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.plant_text {
  color: #476807;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 700;
}
/* .step {
  height: 50vh !important;
  margin: 10px 5px;
  width: 100%;
  object-fit: contain;
} */

.landing_cont {
  background-color: rgb(34, 33, 97);
}
.landing_img_1 {
  height: 60vh !important;
}
.landing_img_2 {
  height: 60vh !important;
}
.pledge_btn_org {
  border-radius: 30px !important;
  padding: 7px 13px;
  min-width: 147px;
  width: fit-content;
  backdrop-filter: blur(8px) !important;
  color: #fff !important;
  border: none;
  border: 1px solid #f86805 !important;
  background: #f6911d !important;
  box-shadow: 0px 2.999999761581421px 6.999999523162842px 0px
    rgba(0, 0, 0, 0.35);
}
.pledge_btn_white {
  border-radius: 30px !important;
  padding: 7px 13px;
  min-width: 147px;
  width: fit-content;
  backdrop-filter: blur(8px) !important;
  color: #f86805 !important;
  border: none;
  border: 1px solid #f6911d !important;
  background: #fff !important;
  box-shadow: 0px 2.999999761581421px 6.999999523162842px 0px
    rgba(0, 0, 0, 0.35);
}
.pledge_btn {
  border-radius: 30px !important;
  padding: 7px;
  min-width: 147px;
  width: fit-content;
  backdrop-filter: blur(8px) !important;
  color: #1b8000 !important;
  border: none;
  border: 0.86px solid #1b8000 !important;
  background: #f5edd4 !important;
  box-shadow: 0px 2.999999761581421px 6.999999523162842px 0px
    rgba(0, 0, 0, 0.35);
}
.submit_btn {
  margin-top: 1em;
  padding: 0.5em 1em;
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
}

.submit_btn:hover {
  background-color: #45a049;
}
.phase_btn {
  border-radius: 10px;
  padding: 10px;
  width: 300px !important;
  border: 0.86px solid #f86805;
  backdrop-filter: blur(8px) !important;
  color: #000;
  box-shadow: 0px 2.999999761581421px 6.999999523162842px 0px
    rgba(0, 0, 0, 0.35);
  background: #ce5702b5;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px !important
  ;
}
.event_btn {
  border-radius: 30px;
  padding: 13px;
  min-width: 160px;
  width: fit-content;
  border: 0.86px solid #f86805;
  backdrop-filter: blur(8px) !important;
  color: #ab4600;
  box-shadow: 0px 2.999999761581421px 6.999999523162842px 0px
    rgba(0, 0, 0, 0.35);
  background: #f5edd4;
}
.date_btn {
  border-radius: 30px;
  padding: 9px;
  width: fit-content;
  border: 0.86px solid #f86805;
  backdrop-filter: blur(8px) !important;
  color: #ab4600;
  box-shadow: 0px 2.999999761581421px 6.999999523162842px 0px
    rgba(0, 0, 0, 0.35);
  background: #f5edd4;
}
.date {
  border-radius: 30px;
  padding: 9px;
  width: 150px !important;
  border: none !important;
  backdrop-filter: blur(8px) !important;
  color: #c10000;
  background-color: transparent !important;
}
.clear_btn {
  border-radius: 30px;
  padding: 9px;
  width: 150px !important;
  border: none !important;
  backdrop-filter: blur(8px) !important;
  color: #c10000;
  text-decoration: underline;
  background-color: transparent !important;
}

.card {
  border-radius: 3px;
  background: linear-gradient(132deg, #c19840 0%, #885d1c 100%);
  /* background: #FFF6D8; */
  box-shadow: 0px 0.15716683864593506px 0.7858342528343201px 0px
      rgba(0, 0, 0, 0.15),
    0px 0.15716683864593506px 1.5716685056686401px 0px #5a2e00 inset,
    0px 1.2223784923553467px 3.3615407943725586px 0px rgba(251, 201, 38, 0.63);
  backdrop-filter: blur(4px);
  color: #937103;
}
/* .card-body{
  justify-content: center;
  display: grid;
} */

.step {
  height: 320px;
  /* min-height: 250px; */
  min-width: 220px;
  /* height: 50vh !important; */
  margin: 10px 5px;
  width: 300px;
  object-fit: contain;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
  border-radius: 8px;
  background: #fff6d8;
  box-shadow: 4px 8px 6px 0px rgba(140, 140, 140, 0.4);

  /* background: linear-gradient(168deg, rgb(255 143 17) 0%, rgba(251, 251, 236, 0.00) 29.17%, rgba(253, 253, 238, 0.00) 78.13%, rgb(148 181 109) 100%); */
  display: flex;
  align-items: center;
}
.step_inner {
  /* background-color: #fff; */
  border-image: linear-gradient(#f86805, #248c00) 60;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  /* mix-blend-mode: color-burn; */
  justify-content: center;
  display: grid;
}
.step_img {
  height: 15vh;
}
.step_text {
  color: #543c00;
  text-align: center;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  display: flex;
  align-items: center;
}
.step_title {
  color: #543c00;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}

/* .step-cards{
  padding-bottom: 10px;
  border-image: linear-gradient(#f86805, #248c00) 60;
  border-width: 2px;
  border-style: solid;
  width: 22rem;
  border-radius: 5px !important;
  backdrop-filter: blur(30px);
  background-color: transparent !important;
} */

.download-cards {
  min-height: 23rem !important;
  padding-bottom: 10px;
  margin: 20px 10px;
  border-image: linear-gradient(#f86805, #248c00) 60;
  border-width: 2px;
  border-style: solid;
  width: 22rem;
  border-radius: 5px !important;
  backdrop-filter: blur(30px);
}
.about-profile-block {
  min-height: 18rem;
  padding-bottom: 10px;
  margin: 20px 10px;
  background: url(data:image/png;base64,PHN2ZyB3aWR0aD0iNDA2IiBoZWlnaHQ9IjQ0MCIgdmlld0JveD0iMCAwIDQwNiA0NDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjAuNSIgeT0iMC41IiB3aWR0aD0iNDA1IiBoZWlnaHQ9IjQzOSIgcng9IjMuNSIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzk4NV8xMDI5NCkiIGZpbGwtb3BhY2l0eT0iMC41IiBzdHJva2U9InVybCgjcGFpbnQxX2xpbmVhcl85ODVfMTAyOTQpIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfOTg1XzEwMjk0IiB4MT0iMjYuNzk2IiB5MT0iLTE3LjU1OTYiIHgyPSIxMTQuNTI4IiB5Mj0iNDkyLjU1NiIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRkY2QzAwIi8+CjxzdG9wIG9mZnNldD0iMC4yOTE2NjciIHN0b3AtY29sb3I9IiNGQkZCRUMiIHN0b3Atb3BhY2l0eT0iMCIvPgo8c3RvcCBvZmZzZXQ9IjAuNzgxMjUiIHN0b3AtY29sb3I9IiNGREZERUUiIHN0b3Atb3BhY2l0eT0iMCIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMxRThEMDAiLz4KPC9saW5lYXJHcmFkaWVudD4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDFfbGluZWFyXzk4NV8xMDI5NCIgeDE9IjIwMyIgeTE9IjAiIHgyPSIyMDMiIHkyPSI0NDAiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0Y4NjgwNSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMyNDhDMDAiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K);
  border-image: linear-gradient(#f86805, #248c00) 60;
  border-width: 2px;
  border-style: solid;
  width: 14rem !important;
  border-radius: 5px !important;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.card-title {
  color: #e7d3a9 !important;
  font-family: "Inter";
  font-size: 15px;
  line-height: normal;
  font-weight: 700;
}

.card-text {
  color: #1b8000 !important;
  font-family: "Inter";
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
}
.span_card_text {
  color: #000;
  font-family: "Inter";
  font-size: 16px;
  line-height: normal;
}
.name-input {
  border-radius: 70px;
  border: 1.5px solid #2d1b00;
  opacity: 0.4;
  background: #e7d3a9;
  height: 36px;
  width: 100%;
  font-weight: 700;
  margin: 20px 0px;
  padding: 0px 10px;
}
.error-message {
  color: red;
}
.select-input {
  border-radius: 70px;
  border: 1.5px solid #2d1b00;
  opacity: 0.4;
  background: #e7d3a9;
  height: 36px;
  width: 200px;
  text-align: center;
}

.state_input {
  border-radius: 70px;
  border: 1.5px solid #2d1b00;
  opacity: 0.4;
  background: #e7d3a9;
  height: 36px;
  margin: 10px 0px;
  width: 100%;
}
.form_input_title {
  color: #543c00;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 700;
  justify-content: start;
  display: flex;
}
.form_title {
  font-size: 20px;
}
.quote {
  color: #381d00;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
}
.akam {
  height: 80px !important;
}
.mmmd_date {
  font-weight: 500;
  line-height: 24px;
  font-size: 20px;
  color: rgb(134, 109, 64);
  text-align: center;
}

/* .step_title {
  color: #543c00;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
} */

.login-input {
  border-radius: 70px;
  border: 1.5px solid #2d1b00;
  opacity: 0.4;
  background: #e7d3a9;
  height: 50px;
  width: 100%;
  /* text-align: center; */
  padding-left: 20px;
}
.form_title {
  font-weight: 700;
  font-size: 30px;
}
.form_login {
  color: #543c00;
  margin: auto;
  height: 100%;
}

.img_box {
  border-radius: 13.235px;
  border: 2px solid #7e735d;
  background: #efe3c3;
  height: 240px;
  width: 250px;
  text-align: center;
}

.form_title {
  font-weight: 700;
  font-size: 26px;
}
.form_login {
  color: #543c00;
  margin: auto;
  height: 100%;
}

.upload_button {
  border-radius: 30px;
  padding: 9px;
  width: 100px;
  backdrop-filter: blur(8px);
  color: #2d1b00 !important;
  border: none;
  border: 0.86px solid #2d1b00;
  background: #f5edd4;
  box-shadow: 0px 2.999999761581421px 6.999999523162842px 0px
    rgba(0, 0, 0, 0.35);
}

.uplaod_div {
  font-size: 16px;
}
.upload_text,
.login_data {
  color: #cc9300;
}

@media screen and (max-width: 600px) {
  .name-input {
    width: 100%;
    margin: 3px 0px;
    height: 36px;
  }
  .form_input_title,
  .form_title {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .main_body {
    background-image: url(../../../public/asset/images/background/bg_mb.jpg) !important;
  }
}

/* welcome page ------------------ */
.welcome_page {
  margin: 200px 5px;
  padding: 5px;
}
.login_details {
  font-weight: 600;
}
@media screen and (max-width: 600px) {
  .pledge_btn {
    padding: 11px;
    min-width: 140px;
  }
  .landing_img_1 {
    height: 30vh !important;
  }
  .landing_img_2 {
    height: 30vh !important;
    width: auto;
  }
}
@media screen and (max-width: 1000px) {
  .landing_img_1 {
    height: 30vh !important;
  }
  .landing_img_2 {
    height: 30vh !important;
    width: auto;
  }
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/* CSS for Dashboard Page */

.dashboard-block {
  padding: 10px;
  margin: 20px 10px;
  background: linear-gradient(
    180deg,
    rgba(255, 108, 0, 0.5) 0%,
    rgba(251, 251, 236, 0) 29.17%,
    rgba(253, 253, 238, 0) 78.13%,
    rgba(30, 141, 0, 0.5) 100%
  );

  /* background: url("../../../../public/asset/images/background/Preview.jpg"); */
  border-image: linear-gradient(#f86805, #248c00) 60;
  border-width: 2px;
  border-style: solid;
  /* border-radius: 15px !important; */
  backdrop-filter: blur(30px);
}
.heading_dash {
  color: #442a00;
}

.custom-card {
  background: #ffffff !important;
  border-radius: 12px !important;
  color: #381d00 !important;
  font-weight: 500;
}
.custom-card .count {
  color: #449800 !important;
  font-weight: 700;
  font-size: 2.5vh;
}
.custom-card .topic {
  color: #925800 !important;
  font-weight: 700;
  font-size: 14px;
}
.custom-card .counter {
  font-weight: 700;
  font-size: 2vh;
  color: #f86805;
}

#searhBox {
  width: 80vw;
  height: "40px";
  padding: 8px;
  border-radius: 25px;
  outline: none;
  border: 1px solid #885d1c;
  margin-bottom: 2rem;
  background-color: #ffefcb !important;
}
.card_img {
  height: 200px;
}
.card {
  height: 20rem;
}
.carousel-indicators [data-bs-target] {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}
#justify-tab-example-tab-home {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
#justify-tab-example-tab-state {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
#justify-tab-example-tab-village {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
#justify-tab-example-tab-profile {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
#justify-tab-example-tab-dashboard {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}

.content-body-nodal .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #885d1c !important;
  color: white !important;
}

.tab-content {
  padding: 24px;
}

select:disabled {
  opacity: 0.6 !important;
}
.carousel-indicators [data-bs-target] {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}
.carousel img {
  border-radius: 10px;
}

.download-btn {
  border-radius: 30px !important;
  padding: 7px;
  width: fit-content;
  backdrop-filter: blur(8px) !important;
  color: #1b8000 !important;
  border: none;
  border: 0.86px solid #1b8000 !important;
  background: #f5edd4 !important;
  box-shadow: 0px 2.999999761581421px 6.999999523162842px 0px
    rgba(0, 0, 0, 0.35);
}
.react-datepicker-popper {
  z-index: 100 !important;
}
.custom-input-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f8680596;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.file_name {
  height: 60px !important;
  overflow-y: auto !important;
  margin-bottom: 5px;
}

.note_text {
  font-size: 12px;
  color: #919191;
  font-weight: 400;
  font-style: italic;
}

.toolTipList {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
}

.mySlides {
  display: none;
}

/* Slideshow container */
.slideshow-container {
  width: 200px;
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 5%;
  width: auto;
  padding: 5px;
  margin-top: -10px;
  color: black;
  font-weight: bold;
  font-size: 15px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 10px;
  border-radius: 3px 0 0 3px;
}
.prev {
  left: 10px;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Caption text */
.toolTipTextSec {
  color: #212121;
  font-size: 14px;
  position: absolute;
  top: 40px;
  width: 100%;
  height: 150px;
  overflow: auto;
}

.toolTipList li {
  display: block;
  padding: 2px 10px;
  font-size: 10px;
}

.toolTipList li label,
.toolTipList li span {
  width: 50%;
}
.toolTipList li label {
  font-weight: 600;
}

/* Number text (1/3 etc) */
.numbertext {
  color: black;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
}

.dbut {
  background-color: darkorange;
  border: none;
  width: 80px;
  padding: 3px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}

hr.grn,
hr.org {
  opacity: inherit;
  margin: 0.25rem 0 !important;
}
hr.org {
  border-top: 4px solid #f58322;
}

hr.grn {
  border-top: 4px solid #229e3d;
}

#justify-tab-example-tab-household {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}

#justify-tab-example-tab-national_event {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}

#justify-tab-example-tab-29_oct {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}

#justify-tab-example-tab-30_oct {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}

#justify-tab-example-tab-31_oct {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
#justify-tab-example-tab-journey {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
#justify-tab-example-tab-kalash {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
#justify-tab-example-tab-block {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}

#justify-tab-example-tab-Add-Dignitaries {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}

#justify-tab-example-tab-upload {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
.react-datepicker__input-container .rounded {
  font-size: 15px;
  color: #543c00;
  padding: 8px;
  height: 40px;
  border-radius: 15px !important;
  border: 1px solid #543c00;
  background: #e7d3a9;
  width: 200px;
}
.form_card {
  height: auto;
  width: 100%;
  border: 1px solid #543c00;
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  background: #fff;
  /* background: linear-gradient(115.02deg, #00833045 0%, #00471a45 93.55%); */
}
.household_title {
  font-size: 25px;
  color: #543c00;
}
.note {
  font-size: 16px;
  color: #919191;
  font-weight: 400;
  font-style: italic;
}
#justify-tab-example-tab-programme_details {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
#justify-tab-example-tab-download_media {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
#justify-tab-example-tab-information_center {
  border: 1px solid #885d1c !important;
  color: #885d1c;
}
