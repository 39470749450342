.report-container {
    margin: 20px;
  }
  
  .report-title {
    font-size: 24px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  
  .table-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
  }
  
  .report-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .report-table th,
  .report-table td {
    padding: 8px 12px;
    text-align: left;
  }
  
  .report-table th {
    background-color: #f0f0f0;
    font-weight: bold;
    font-size: 14px;
  }
  
  .report-table tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  .report-table tr:nth-child(odd) {
    background-color: burlywood;
  }
  

  .state-link{
    color: blue !important;
    text-decoration: underline !important;
    font-size: 13px;
    cursor: pointer !important;
  }
  .report_active{
    backdrop-filter: blur(8px) !important;
    color: #1b8000 !important;
    text-decoration: underline !important;
    font-size: 16px;
    margin: 10px;
  }
  .report_btn_active{
    border-radius: 30px !important;
    padding: 7px;
    min-width: 147px;
    width: fit-content;
    backdrop-filter: blur(8px) !important;
    color: #f5edd4 !important;
    border: none;
    border: 0.86px solid #f5edd4 !important;
    background: #1b8000  !important;
    box-shadow: 0px 2.999999761581421px 6.999999523162842px 0px
      rgba(0, 0, 0, 0.35);
  }
  .report_btn_inactive{
    border-radius: 30px !important;
    padding: 7px;
    min-width: 147px;
    width: fit-content;
    backdrop-filter: blur(8px) !important;
    color: #1b8000 !important;
    border: none;
    border: 0.86px solid #1b8000 !important;
    background: #f5edd4 !important;
    box-shadow: 0px 2.999999761581421px 6.999999523162842px 0px
      rgba(0, 0, 0, 0.35);
  }