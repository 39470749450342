.header_text{
    color: #442A00;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
/* .navbar-toggler{
    border: none !important;
}
navbar-toggler:focus {
    outline: none !important;
    box-shadow: none !important;
} */

.dropdown-menu[data-bs-popper] {
    left: -54px !important;
}
.logo{
    height: "50px";
}
#basic-nav-dropdown{
    display: -webkit-box !important;
}
.dropdown-menu-show{
    background: transparent !important;
    backdrop-filter: blur(35px) !important;
}
/* .navbar{
    background: transparent !important;
}

.offcanvas{
    background: transparent !important;
    backdrop-filter: blur(30px) !important;
    color: #fff !important;
    width: 70% !important;
}
.btn-close {
    background: url(../../../../public/close.svg) !important;
    height: 6px !important;
    width: 6px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
} */

.offcanvas{
    /* background: transparent !important;
    backdrop-filter: blur(30px) !important;
    color: #fff !important; */
    width: 70% !important;
}
.event_btn{
    background-image: url(../../../../public/asset/images/green_bg.svg);
    background-color: rgb(0, 129, 26);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: rgb(255, 255, 255);
    min-height: 7vh;
    height: auto;
    width: fit-content;
    border-radius: 10px;
    border: 2px solid green;
    cursor: pointer;
}